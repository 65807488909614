<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />
    <el-backtop target=".el-main"></el-backtop>

    <div class="search">
      <el-select
        v-model="valueArea"
        filterable
        clearable
        placeholder="请选择区号(可搜索关键字)"
        style="width: 240px; margin-right: 10px"
        v-if="internationType"
      >
        <el-option
          v-for="item in options"
          :key="item.name"
          :label="item.name + item.tel"
          :value="item.tel"
        >
        </el-option>
      </el-select>
      <el-input placeholder="请输入手机号" v-model="phone"></el-input>
      <el-input
        placeholder="请输入要查询的钱包地址"
        style="width: 300px"
        v-model="walletUrl"
      ></el-input>
      <div>
        <el-button type="primary" @click="searchList">搜索</el-button>
        <el-button type="info" @click="reset">重置</el-button>
      </div>
    </div>

    <div class="table_con">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" label="序号" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="ID" width="100"> </el-table-column>
        <el-table-column prop="phone" label="手机号" width="140">
          <template slot-scope="scope">
            {{ scope.row.phoneArea }} {{ scope.row.phone }}
          </template>
        </el-table-column>
        <el-table-column prop="orderId" label="订单id" width="180">
        </el-table-column>
        <el-table-column prop="wallet" label="钱包地址" width="220">
        </el-table-column>

        <el-table-column
          prop="payTime"
          label="支付时间"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="payType" label="支付类型" align="center">
        </el-table-column>
        <el-table-column
          prop="payImg"
          label="支付截图"
          width="140"
          align="center"
        >
          <template slot-scope="scope">
            <el-image
              style="width: 100%; height: 100%"
              :src="scope.row.payImg"
              :preview-src-list="[scope.row.payImg]"
            >
            </el-image>
          </template>
        </el-table-column>

        <el-table-column fixed="right" label="操作" width="120" align="center">
          <template slot-scope="scope">
            <div class="operation">
              <el-button
                @click="handleClick(scope.row, 1)"
                type="text"
                size="small"
                >审核通过</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="handleClick(scope.row, 2)"
                >拒绝通过</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
        :page="currentPage"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";

import { showLoading, hideLoading } from "@/util/loading";
import { areaCodeData } from "@/util/areaCodeData";

export default {
  components: { Breadcrumb, Pagination },
  inject: ["reload"],
  data() {
    return {
      BreadcrumbCon: [
        {
          name: "资产审核",
        },
        {
          name: "支付审核",
        },
        {
          name: "待审核列表",
        },
      ], // 面包屑数据

      phone: "",
      walletUrl: "",
      valueArea: "",
      options: [],

      tableData: [],

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100, 200],

      internationType: false,
      role: "",
      phoneAreaROLE: "",
    };
  },

  mounted() {
    this.options = areaCodeData;

    this.role = localStorage.getItem("ROLE");
    var strNation = this.role.includes("ROLE_nation");
    if (strNation) {
      this.internationType = true;
    }
    this.phoneAreaROLE = localStorage.getItem("phoneArea");

    showLoading();
    const opt = {
      url: reqUrl.getPayListExamine,
      method: "POST",
      params: JSON.stringify({
        phone: "",
        phoneArea: this.phoneAreaROLE,
        payStatus: "1",
        wallet: "",
        pageNum: 0,
        pageSize: 0,
      }),
      resFunc: (res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.data.list;
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;
          this.currentPage =
            (localStorage.getItem("TravelWindingPaymentVaitPageNum") || 1) * 1;
          hideLoading();

          res.data.data.list.map((item) => {
            this.changeItem(item);
          });
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(opt);
  },

  methods: {
    searchList() {
      if (this.phone || this.walletUrl || this.valueArea) {
        showLoading();
        const opt = {
          url: reqUrl.getPayListExamine,
          method: "POST",
          params: JSON.stringify({
            phone: this.phone,
            phoneArea: this.valueArea || this.phoneAreaROLE,
            payStatus: "1",
            wallet: this.walletUrl,
            pageNum: 0,
            pageSize: 0,
          }),
          resFunc: (res) => {
            if (res.data.code == 0) {
              this.tableData = res.data.data.list;
              this.pageLength = res.data.data.total;
              this.pageSize = res.data.data.pageSize;
              hideLoading();

              res.data.data.list.map((item) => {
                this.changeItem(item);
              });
            }
            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            }
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
            hideLoading();
          },
        };
        request(opt);
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可搜索项",
        });
      }
    },

    changeItem(item) {
      if (item.payType == 1) {
        item.payType = "余额账户";
      } else if (item.payType == 2) {
        item.payType = "CPLE积分账户";
      } else if (item.payType == 3) {
        item.payType = "支付宝账户";
      } else if (item.payType == 4) {
        item.payType = "微信账户";
      } else if (item.payType == 5) {
        item.payType = "京东账户";
      } else if (item.payType == 6) {
        item.payType = "转出额度";
      } else if (item.payType == 7) {
        item.payType = "USDT";
      } else if (item.payType == 8) {
        item.payType = "链上CPLE TOKEN";
      }
    },

    handlePageChange(data) {
      localStorage.setItem("TravelWindingPaymentVaitPageNum", data.pageIndex);
      showLoading();
      const opt = {
        url: reqUrl.getPayListExamine,
        method: "POST",
        params: JSON.stringify({
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          payStatus: "1",
          wallet: this.wallet,
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
        }),
        resFunc: (res) => {
          if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;
            hideLoading();

            res.data.data.list.map((item) => {
              this.changeItem(item);
            });
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },

    handleClick(row, index) {
      if (index == 1) {
        this.$confirm("您确定要执行该操作吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            const opt = {
              url: reqUrl.payAuditNftUrl,
              method: "POST",
              params: JSON.stringify({
                result: 2,
                orderId: row.orderId,
                id: row.id,
                examineReason: "",
                auditor: localStorage.getItem("userName"),
              }),
              resFunc: (res) => {
                console.log(res.data);
                if (res.data.code == 0) {
                  this.$message({
                    type: "success",
                    message: "操作成功!",
                  });

                  this.refresh();
                }
                hideLoading();

                if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                  hideLoading();
                }
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                console.log(err, "err1");
                hideLoading();
              },
            };
            request(opt);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      } else if (index == 2) {
        this.$prompt("请输入拒绝原因", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputPattern: /^.+$/,
          inputErrorMessage: "请输入拒绝原因",
        }).then(({ value }) => {
          showLoading();
          const opt = {
            url: reqUrl.payAuditNftUrl,
            method: "POST",
            params: JSON.stringify({
              result: 3,
              orderId: row.orderId,
              id: row.id,
              examineReason: value,
              auditor: localStorage.getItem("userName"),
            }),
            resFunc: (res) => {
              console.log(res.data);
              hideLoading();

              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });

                this.refresh();
                hideLoading();
              }

              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              }
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              console.log(err, "err1");
              hideLoading();
            },
          };
          request(opt);
        });
      }
    },

    reset() {
      if (this.phone || this.walletUrl) {
        this.phone = "";
        this.walletUrl = "";
        this.refresh();
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可重置项",
        });
      }
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },

  destroyed() {
    localStorage.removeItem("TravelWindingPaymentVaitPageNum");
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;

  .el-input {
    width: 250px;
    margin-right: 10px;
  }
}
.table_con {
  margin-top: 10px;
}

.operation {
  display: flex;
  flex-direction: column;
  color: #b4b4b4;
  font-weight: bold;

  .el-button + .el-button {
    margin-left: 0;
    margin-top: 2px;
  }

  .el-button {
    border: 1px #ccc solid;
    color: #666;
    background: #d9d9d9;
  }
}
.cropper-content {
  width: 500px;
  height: 500px;
  background: pink;
  margin: 0 auto;
}
.cropper {
  width: 500px;
  height: 500px;
  background: yellow;
}
</style>
